import React, { useState, useEffect } from 'react'
import './dadosUsuario.css';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import { getAuth, updatePassword, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';
import { getFirestore, doc, updateDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';


function DadosUsuario({setNotification}) {
  const [isOpen, setIsOpen] = useState(false);

  const [userData, setUserData] = useState(null);

  const [telefoneFormatado, setTelefoneFormatado] = useState('');
  const [telefoneValido, setTelefoneValido] = useState(true);

  const [senhaAtual, setSenhaAtual] = useState('');
  const [novaSenha, setNovaSenha] = useState('');
  const [confirmarNovaSenha, setConfirmarNovaSenha] = useState('');

  const [showPassword, setShowPassword] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  // Listar Dados do usuario
  useEffect(() => {
    const fetchUserData = async () => {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const db = getFirestore();
        const userEmail = user.email;

        try {
          // Procura nas três coleções
          const basicQuery = query(collection(db, 'userBasic'), where('email', '==', userEmail));
          const basicSnapshot = await getDocs(basicQuery);
          if (!basicSnapshot.empty) {
            setUserData(basicSnapshot.docs[0].data());
            return;
          }

          const mastersQuery = query(collection(db, 'userMasters'), where('email', '==', userEmail));
          const mastersSnapshot = await getDocs(mastersQuery);
          if (!mastersSnapshot.empty) {
            setUserData(mastersSnapshot.docs[0].data());
            return;
          }

          const premiumQuery = query(collection(db, 'userPremium'), where('email', '==', userEmail));
          const premiumSnapshot = await getDocs(premiumQuery);
          if (!premiumSnapshot.empty) {
            setUserData(premiumSnapshot.docs[0].data());
            return;
          }
        } catch (error) {
          setNotification({ message: 'Erro ao buscar usuário.', type: 'error' })
        }
      }
    };

    fetchUserData();
  }, [setNotification]);

  // validacao telefone
  useEffect(() => {
    if (userData && userData.telefone) {
      formatarTelefone(userData.telefone);
    }
  }, [userData]);


  const formatarTelefone = (numero) => {
    const regex = /^(\d{2})(\d{5})(\d{4})$/;
    const match = numero.match(regex);

    if (match) {
      const telefoneFormatado = `(${match[1]}) ${match[2]}-${match[3]}`;
      setTelefoneFormatado(telefoneFormatado);
      setTelefoneValido(true);
    } else {
      setTelefoneFormatado(numero);
      setTelefoneValido(false);
    }
  };

  // Atualizar dados do usuario nome e telefone
  const atualizarDadosUsuario = async (e) => {
    e.preventDefault();

    if (!telefoneValido) {
      setNotification({ message: 'Digite um numero de telefone válido.', type: 'warning' })
      return;
    }

    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      const db = getFirestore();
      const userEmail = user.email;

      try {
        // Atualiza nas três coleções
        const basicQuery = query(collection(db, 'userBasic'), where('email', '==', userEmail));
        const basicSnapshot = await getDocs(basicQuery);
        if (!basicSnapshot.empty) {
          const basicDocId = basicSnapshot.docs[0].id;
          const basicDocRef = doc(db, 'userBasic', basicDocId);
          await updateDoc(basicDocRef, {
            nome: userData.nome,
            telefone: userData.telefone,
          });
        }

        const mastersQuery = query(collection(db, 'userMasters'), where('email', '==', userEmail));
        const mastersSnapshot = await getDocs(mastersQuery);
        if (!mastersSnapshot.empty) {
          const mastersDocId = mastersSnapshot.docs[0].id;
          const mastersDocRef = doc(db, 'userMasters', mastersDocId);
          await updateDoc(mastersDocRef, {
            nome: userData.nome,
            telefone: userData.telefone,
          });
        }

        const premiumQuery = query(collection(db, 'userPremium'), where('email', '==', userEmail));
        const premiumSnapshot = await getDocs(premiumQuery);
        if (!premiumSnapshot.empty) {
          const premiumDocId = premiumSnapshot.docs[0].id;
          const premiumDocRef = doc(db, 'userPremium', premiumDocId);
          await updateDoc(premiumDocRef, {
            nome: userData.nome,
            telefone: userData.telefone,
          });
        }
        setNotification({ message: 'Dados atualizados com sucesso!', type: 'success' })
      } catch (error) {
        setNotification({ message: 'Erro ao atualizar dados do usuário.', type: 'error' })
      }
    }
  };

  // Alterar senha
  const alterarSenha = async (e) => {
    e.preventDefault();

    if (novaSenha !== confirmarNovaSenha) {
      setNotification({ message: 'As novas senhas não coincidem.', type: 'warning' })
      return;
    }

    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      try {
        // Reautentica o usuário antes de tentar alterar a senha
        const credential = EmailAuthProvider.credential(user.email, senhaAtual);
        await reauthenticateWithCredential(user, credential);

        // Altera a senha
        await updatePassword(user, novaSenha);

        setNotification({ message: 'Senha alterada com sucesso', type: 'success' })
        setSenhaAtual('');
        setNovaSenha('');
        setConfirmarNovaSenha('');
      } catch (error) {
        handleRegistrationError(error);
        setNotification({ message: 'Verifique seus dados e tente novamente.', type: 'warning' })
      }
    }
  };

  function handleRegistrationError(error) {
    if (error.code === 'auth/weak-password') {
      setNotification({ message: 'Email já cadastrado.', type: 'warning' })
    }
  }



  return (
    <>
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      <div className='container'>
        <Header toggleSidebar={toggleSidebar} />
        <div className='padding' />

        <div className='content'>
          <div className='usuarios-table'>
            <h2>Dados Pessoais</h2>
            {userData && (
              <form onSubmit={atualizarDadosUsuario}>
                <div className="input-group">
                  <label>Plano:</label>
                  <input type="text" value={userData.perfil} disabled className={`perfil-${userData.perfil.toLowerCase()}`}/>
                </div>
                <div className="input-group">
                  <label>Email:</label>
                  <input type="text" value={userData.email} disabled />
                </div>
                <div className='input-group'>
                  <label>Nome:</label>
                  <input
                    type='text'
                    value={userData.nome}
                    onChange={(e) => setUserData({ ...userData, nome: e.target.value })}
                  />
                </div>
                <div className='input-group'>
                  <label>Whatsapp:</label>
                  <input
                    type="text"
                    value={telefoneFormatado}
                    onChange={(e) => {
                      const numeroLimpo = e.target.value.replace(/\D/g, '');
                      setUserData({ ...userData, telefone: numeroLimpo });
                      formatarTelefone(numeroLimpo);
                    }}
                  />
                </div>
                <button className="btn-outros" type="submit">Salvar</button>
              </form>
            )}
          </div>
        </div>

        <div className='content'>
          <div className='usuarios-table'>
            <h2>Alterar Senha</h2>
            <form onSubmit={alterarSenha}>
              <div className='input-group'>
                <label>Senha Atual:</label>
                <div className="password-input">
                  <input
                    type={showPassword ? "text" : "password"}
                    value={senhaAtual}
                    onChange={(e) => setSenhaAtual(e.target.value)}
                    required
                  />
                  <FontAwesomeIcon
                    icon={showPassword ? faEyeSlash : faEye}
                    className="eye-icon"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                </div>
              </div>
              <div className='input-group'>
                <label>Nova Senha:</label>
                <div className="password-input">
                  <input
                    type={showPassword ? "text" : "password"}
                    value={novaSenha}
                    onChange={(e) => setNovaSenha(e.target.value)}
                    required
                  />
                  <FontAwesomeIcon
                    icon={showPassword ? faEyeSlash : faEye}
                    className="eye-icon"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                </div>
              </div>
              <div className='input-group'>
                <label>Confirmar Nova Senha:</label>
                <div className="password-input">
                  <input
                    type={showPassword ? "text" : "password"}
                    value={confirmarNovaSenha}
                    onChange={(e) => setConfirmarNovaSenha(e.target.value)}
                    required
                  />
                  <FontAwesomeIcon
                    icon={showPassword ? faEyeSlash : faEye}
                    className="eye-icon"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                </div>
              </div>
              <button className='btn-outros' type='submit'>
                Salvar Nova Senha
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default DadosUsuario;

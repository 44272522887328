import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import Sidebar from '../../components/Sidebar';
import Header from '../../components/Header';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import CapaVideo from '../../assets/img/bannermpm.jpeg';
import Telegram from '../../assets/img/chamada-telegram.png';


function BoasVindas() {
  const [isOpen, setIsOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const fetchBoasVindasData = async () => {
      try {
        const firestore = getFirestore();
        const boasVindasRef = collection(firestore, 'BoasVindas');
        const q = await getDocs(boasVindasRef);

        if (!q.empty) {
          const latestBoasVindas = q.docs[q.docs.length - 1].data();
          setVideoUrl(latestBoasVindas);
        }
      } catch (error) {
        console.error('Erro ao buscar dados de boas-vindas:', error);
      }
    };

    fetchBoasVindasData();
  }, []);

  return (
    <>
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      <div className='container'>
        <Header toggleSidebar={toggleSidebar} />
        <div className='padding' />
        <div className='content'>
          <div className="boas-vindas-container">
            <div className="video-boasvindas">
              {videoUrl && (
                <video controls playsInline poster={CapaVideo}>
                  <source src={videoUrl.videoUrl} type="video/mp4" />
                  Seu navegador não suporta o elemento de vídeo.
                </video>
              )}

              <div>
                <h2>Bem-vinda | Positive-se Mulher</h2>
                <p>Uma comunidade de mulheres positivas e de fé em busca de um melhor potencial na vida pessoal e profissional. Seja bem-vindas a este espaço de crescimento, apoio mútuo e inspiração. Juntas, vamos construir um futuro brilhante, onde cada uma de nós possa florescer plenamente e Que esta jornada seja repleta de aprendizados. </p>
              </div>
            </div>

            <div className='lista-itens'>
              <ul>
                <li>
                  <Link to="/conteudos">
                    <FontAwesomeIcon icon={faCheckCircle} className='i' />
                    Conteúdos | Local dos cursos online
                  </Link>
                </li>
                <li>
                  <Link to="/roda-da-mulher-positiva">
                    <FontAwesomeIcon icon={faCheckCircle} className='i' />
                    Roda da Mulher Positiva
                  </Link>
                </li>
                <li>
                  <Link to="/albuns">
                    <FontAwesomeIcon icon={faCheckCircle} className='i' />
                    Fotos dos nossos encontros
                  </Link>
                </li>
                <li>
                  <Link to="/encontros">
                    <FontAwesomeIcon icon={faCheckCircle} className='i' />
                    Vídeos dos nossos encontros
                  </Link>
                </li>
                <li>
                  <Link to="/dados-usuario">
                    <FontAwesomeIcon icon={faCheckCircle} className='i' />
                    Dados do usuário | Acesso pelo seu nome no topo da página
                  </Link>
                </li>
                <li>
                  Você tem acesso a todos esses itens pelo menu lateral a esquerda
                </li>
              </ul>

              <ul>
                <li><a href='/' target='_blanck'><img src={Telegram} alt='Banner telegram' /></a></li>
                <li>Suporte pelo email contato@positiveempresarial.com.br</li>
              </ul>
            </div>
          </div>
        </div>

      </div>
    </>
  );
}

export default BoasVindas;

import React, { useEffect, useState } from 'react';
import { getFirestore, doc, getDoc, collection, getDocs } from 'firebase/firestore';
import './home.css';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';


function Home() {
  const [isOpen, setIsOpen] = useState(false);
  const [encontroData, setEncontroData] = useState(null);
  const [novidadesData, setNovidadesData] = useState(null);
  const [boasVindasData, setBoasVindasData] = useState(null);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const fetchBoasVindasData = async () => {
      try {
        const firestore = getFirestore();
        const boasVindasRef = collection(firestore, 'BoasVindas');
        const q = await getDocs(boasVindasRef);

        if (!q.empty) {
          const latestBoasVindas = q.docs[q.docs.length - 1].data();
          setBoasVindasData(latestBoasVindas);
        }
      } catch (error) {
        console.error('Erro ao buscar dados de boas-vindas:', error);
      }
    };

    fetchBoasVindasData();
  }, []);

  useEffect(() => {
    // Função para buscar os dados do encontro
    const fetchEncontroData = async () => {
      try {
        const firestore = getFirestore();
        const encontroDocRef = doc(firestore, 'encontros', 'YSh69esB6zqdGuD8y5B7');
        const encontroDocSnap = await getDoc(encontroDocRef);

        if (encontroDocSnap.exists()) {
          const data = encontroDocSnap.data();
          setEncontroData(data);
        }
      } catch (error) {
        console.error('Erro ao buscar dados do encontro:', error);
      }
    };

    async function fetchNovidadesData() {
      try {
        const firestore = getFirestore();
        const novidadesCollectionRef = collection(firestore, 'novidades');
        const novidadesDocsSnap = await getDocs(novidadesCollectionRef);

        const novidadesDataArray = [];

        novidadesDocsSnap.forEach((doc) => {
          if (doc.exists()) {
            const data = doc.data();
            novidadesDataArray.push(data);
          }
        });
        setNovidadesData(novidadesDataArray);
      } catch (error) {
        console.error('Erro ao buscar dados do novidades:', error);
      }
    }

    fetchEncontroData();
    fetchNovidadesData();
  }, []);


  return (
    <>
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      <div className='container'>
        <Header toggleSidebar={toggleSidebar} />
        <div className='padding' />

        <div className='content'>
          <div className='cardBoasVindas'>
            {boasVindasData && (
              <Link to='/boas-vindas'>
                <img src={boasVindasData.capaUrl} alt='Capa de Boas-vindas' />
              </Link>
            )}
          </div>
        </div>

        <div className='content'>
          <div className='proximo-econtro'>
            <h2 className='titulos-home'>Próximo encontro</h2>
            {encontroData && (
              <div className="encontro">
                <img src={encontroData.photoURL} alt="Encontro" />
                <div>
                  <h5><FontAwesomeIcon icon={faCalendar} /> {encontroData.data}</h5>
                  <h2>{encontroData.title}</h2>
                  <p>{encontroData.description}</p>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='content'>
          <div className='novidades'>
            <h2 className='titulos-home'>Novidades</h2>

            {novidadesData && (
              <Swiper
              spaceBetween={0} // Espaço entre os slides
              freeMode={true}
              breakpoints={{
                320: { slidesPerView: 1.04 },
                480: { slidesPerView: 1.2 },
                640: { slidesPerView: 1.5 },
                768: { slidesPerView: 2 },
                1024: { slidesPerView: 2.5 },
                1280: { slidesPerView: 3 },
                1440: { slidesPerView: 3.5 },
                1600: { slidesPerView: 4 },
                1920: { slidesPerView: 4.5 }
              }}
              >
                {novidadesData.map((novidade, index) => (
                  <SwiperSlide key={index}>
                    <Link to='https://wa.me/message/55JA72DPZF3XI1' target='_blank'>
                      <img src={novidade.url} alt='Banner' />
                      <div>
                        <h3>{novidade.title}</h3>
                        <p>{novidade.description}</p>
                      </div>
                    </Link>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
        </div>

        <div className='content'>
          <div className='youtube-chanel'>
            <h2 className='titulos-home'>Canal do YouTube</h2>
            <iframe src="https://www.youtube.com/embed/fLdns62sTi0?si=0x5MCBrArA1ueZ_D" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
          </div>
        </div>

      </div >
    </>
  );
}

export default Home;

import React, { useEffect, useState } from 'react';
import { getFirestore, collection, getDocs, query, orderBy } from 'firebase/firestore';
import './encontros.css';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Loading from '../../components/Loading';
import CapaVideo from '../../assets/img/CapaVideoStories.png';


function Encontros() {
  const [isOpen, setIsOpen] = useState(false);
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    async function fetchVideos() {
      const firestore = getFirestore();
      const videosCollection = collection(firestore, 'videos');
      const q = query(videosCollection, orderBy('timestamp', 'desc')); // Aplicando a ordenação correta

      try {
        const videosSnapshot = await getDocs(q); // Usando a variável 'q' para buscar os documentos

        const videosData = [];
        videosSnapshot.forEach((doc) => {
          videosData.push({
            id: doc.id,
            ...doc.data(),
          });
        });

        setVideos(videosData);
      } catch (error) {
        console.error('Erro ao buscar vídeos:', error);
      }
    }

    fetchVideos();
    setLoading(false);
  }, [])


  return (
    <>
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      <div className='container'>
        <Header toggleSidebar={toggleSidebar} />
        <div className='padding' />

        <div className='content'>
          <h2 className='titulos-home'>Ultimos encontros</h2>
          {loading ? (
            <Loading />
          ) : (
            <div className='contentVideo'>
              {videos.map((video) => (
                <div key={video.id}>
                  <video controls playsInline poster={CapaVideo}>
                    <source src={video.url} type="video/mp4" />
                    Seu navegador não suporta a tag de vídeo.
                  </video>
                  <div className='descricaoVideo'>
                    <h3>{video.title}</h3>
                    <p>{video.description}</p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div >
    </>
  )
}

export default Encontros;

import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import './conteudos.css';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, getDoc, doc, updateDoc, query, where, getDocs, collection, setDoc } from 'firebase/firestore';
import Avatar from '../../assets/img/woman.png'
import CapaVideo from '../../assets/img/bannermpm.jpeg';
import Loading from '../../components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircle } from '@fortawesome/free-solid-svg-icons';


function Curso({ setNotification }) {
  const [isOpen, setIsOpen] = useState(false);

  const [user, setUser] = useState(null);

  const [courseDetails, setCourseDetails] = useState(null);

  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');

  const [completedVideos, setCompletedVideos] = useState([]);


  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const { id } = useParams();

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const fetchCourseDetails = async () => {
      try {
        const db = getFirestore();
        const cursoDoc = doc(db, 'videoAulas', id);
        const cursoSnapshot = await getDoc(cursoDoc);

        if (cursoSnapshot.exists()) {
          setCourseDetails({ id: cursoSnapshot.id, ...cursoSnapshot.data() });

          // Carregue os comentários do Firestore
          const commentsData = cursoSnapshot.data().comments || [];
          setComments(commentsData);

          // Marcar aula como concluida
          if (user) {
            const userCompletionDocRef = doc(db, 'videoAulas', id, 'userCompletions', user.uid);
            const userCompletionDocSnapshot = await getDoc(userCompletionDocRef);

            if (userCompletionDocSnapshot.exists()) {
              const userData = userCompletionDocSnapshot.data();
              setCompletedVideos(userData.completedVideos);
            }
          }
        }
      } catch (error) {
        setNotification({ message: 'Erro, tente novamente', type: 'error' });
      }
    };

    fetchCourseDetails();
  }, [id, setNotification, user]);


  const handleCommentChange = (e) => {
    setNewComment(e.target.value);
  };

  const getUserName = async (email) => {
    const db = getFirestore();
    const userCollections = ['userBasic', 'userPremium', 'userMasters'];

    for (const collectionName of userCollections) {
      const q = query(collection(db, collectionName), where('email', '==', email));

      try {
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const userDocData = querySnapshot.docs[0].data();
          const firstName = userDocData.nome.split(' ')[0];
          const lastName = userDocData.nome.split(' ')[1];

          const userName = `${firstName} ${lastName}`;
          return userName;
        }
      } catch (error) {
        setNotification({ message: 'Erro ao carregar.', type: 'error' });
      }
    }

    return null;
  };


  const handleCommentSubmit = async (e) => {
    e.preventDefault();

    try {
      const db = getFirestore();
      const cursoDoc = doc(db, 'videoAulas', id);

      // Obtenha o nome do usuário logado
      const userEmail = user ? user.email : null;
      const userName = userEmail ? await getUserName(userEmail) : 'Nome da Pessoa';

      // Atualize os comentários no Firestore
      await updateDoc(cursoDoc, {
        comments: [...comments, {
          name: userName,
          date: new Date().toLocaleDateString(),
          comment: newComment,
        }],
      });

      // Atualize o estado dos comentários para refletir o novo comentário imediatamente
      setComments([...comments, {
        name: userName,
        date: new Date().toLocaleDateString(),
        comment: newComment,
      }]);

      // Limpe o campo de novo comentário
      setNewComment('');
    } catch (error) {
      setNotification({ message: 'Erro ao adicionar comentário.', type: 'error' });
    }
  };

  // Marcar vídeo como concluído
  const handleVideoCompletion = async (index) => {
    if (user) {
      const updatedCompletedVideos = [...completedVideos];
      updatedCompletedVideos[index] = !completedVideos[index];
    
      try {
        const db = getFirestore();
        const userId = user.uid;
        const userCompletionDocRef = doc(db, 'videoAulas', id, 'userCompletions', userId);
        await setDoc(userCompletionDocRef, { completedVideos: updatedCompletedVideos }, { merge: true });
        setCompletedVideos(updatedCompletedVideos);
      } catch (error) {
        setNotification({ message: 'Erro ao marcar vídeo como concluído.', type: 'error' });
      }
    }
  };
  
  // Renderiza o botao marcado como completo
  useEffect(() => {
    const loadCompletedVideos = async () => {
      if (user) {
        try {
          const db = getFirestore();
          const userId = user.uid
          const userCompletionDocRef = doc(db, 'videoAulas', id, 'userCompletions', userId);
          const userCompletionDocSnapshot = await getDoc(userCompletionDocRef);
          if (userCompletionDocSnapshot.exists()) {
            const userData = userCompletionDocSnapshot.data();
            setCompletedVideos(userData.completedVideos);
          }
        } catch (error) {
          setNotification({ message: 'Erro ao carregar vídeos concluídos.', type: 'error' });
        }
      }
    };

    loadCompletedVideos();
  }, [id, user, setNotification]);


  if (!courseDetails) {
    return <Loading />;
  }

  return (
    <>
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />

      <div className='container'>
        <Header toggleSidebar={toggleSidebar} />
        <div className='padding' />

        <div className='content'>

          <div className="video-container">
            <div className='video-description'>
              <h2>{courseDetails.title}</h2>
              <p>{courseDetails.description}</p>
            </div>

            {courseDetails.videos.map((video, index) => (
              <div key={index} className='div-video'>
                <video controls playsInline poster={CapaVideo}>
                  <source src={video.videoUrl} type="video/mp4" />
                  Seu navegador não suporta o elemento de vídeo.
                </video>
                <h2>{video.title}</h2>
                <div>
                  <button onClick={() => handleVideoCompletion(index)}>
                    {completedVideos[index] ? (
                      <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} />
                    ) : (
                      <FontAwesomeIcon icon={faCircle} style={{ color: '#ca60d6' }} />
                    )}
                    <span style={{ marginLeft: '0.5rem' }}>
                      {completedVideos[index] ? 'Concluído' : 'Marcar como concluído'}
                    </span>
                  </button>
                </div>
              </div>
            ))}

            <div className='ferramenta'>
              <h4>Recurso para a aula</h4>
              <a href={courseDetails.pdfUrl} download={`${courseDetails.title}.pdf`} target='_blank' rel="noreferrer">BAIXAR</a>
            </div>
          </div>

          <div className='content'>
            <div className='comentarios'>
              <span>{comments.length} Comentário</span>
              <form onSubmit={handleCommentSubmit}>
                <img src={Avatar} alt="Avatar" />
                <label htmlFor="comment">Deixe seu comentário:</label>
                <textarea
                  id="comment"
                  name="comment"
                  value={newComment}
                  required
                  onChange={handleCommentChange}
                />
                <button type="submit">Publicar</button>
              </form>

              <div className="comments-section">
                {comments.map((comment, index) => (
                  <div key={index} className="comment">
                    <img src={Avatar} alt="Avatar" />
                    <div className="comment-details">
                      <span className="comment-name">{comment.name}</span>
                      <span className="comment-date">{comment.date}</span>
                      <p className="comment-text">{comment.comment}</p>
                      <br></br>
                      <br></br>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

        </div>

      </div >
    </>
  )
}

export default Curso;

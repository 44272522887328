import { useState } from 'react';
import { auth } from '../../auth/firebaseConnection';
import { sendPasswordResetEmail } from 'firebase/auth';
import { Link } from 'react-router-dom'; 

function ResetPassword({setNotification}) {
  const [email, setEmail] = useState('');

  const handleResetPassword = async (e) => {
    e.preventDefault();

    try {
      await sendPasswordResetEmail(auth, email);
      setNotification({message: 'Link de redefinir senha enviado para seu e-mail..', type: 'success'});
    } catch (error) {
      setNotification({message: 'Erro! Verifique seu e-mail e tente novamente.', type: 'warning'});
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2>Redefinir Senha</h2>
        <form onSubmit={handleResetPassword}>
          <div className="input-group">
            <label>Email:</label>
            <input
              type="email"
              placeholder="Seu email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <button type="submit">Enviar Link de Redefinição de Senha</button>
        </form>
        <p><Link to="/">Voltar Para Login</Link></p>
      </div>
    </div>
  );
}

export default ResetPassword;

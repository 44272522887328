import { useState } from "react";
import './login.css';
import { Link, useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../auth/firebaseConnection";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

function Login({setNotification}) {
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const logarUsuario = async (e) => {
    e.preventDefault();

    if (email !== '' && senha !== '') {
      try {
        await signInWithEmailAndPassword(auth, email, senha);
        setNotification({ message: 'Seja Bem-vinda Positiva.', type: 'success' });
        navigate('/home', { replace: true });

        setEmail('');
        setSenha('');
      } catch (error) {
        setNotification({message: 'Erro, Verifique seus dados e tente novamente.', type: 'error'});
      }
    } else {
      setNotification({message: 'Preencha todos os campos.', type: 'warning'});
    }
  };

  return (
    <div>
      <div className="login-container">
        <div className="login-box">
          <h2>Login</h2>
          <form onSubmit={logarUsuario}>
            <div className="input-group">
              <label>Email:</label>
              <input
                type="text"
                placeholder='Seu email'
                value={email}
                onChange={(e) => setEmail(e.target.value)} />
            </div>
            
            <div className="input-group">
              <label>Senha:</label>
              <div className="password-input">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder='Senha'
                  value={senha}
                  onChange={(e) => setSenha(e.target.value)} />
                <FontAwesomeIcon
                  icon={showPassword ? faEyeSlash : faEye}
                  className="eye-icon"
                  onClick={() => setShowPassword(!showPassword)}
                />
              </div>
            </div>
            <button className="login-button" type="submit">LOGAR</button>
          </form>
          <p><Link to="/reset-password">Esqueci minha senha</Link></p>
        </div>
      </div>
    </div>
  );
}

export default Login;

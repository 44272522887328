import React, { useEffect, useState } from 'react';
import { getStorage, ref, listAll, getDownloadURL } from 'firebase/storage';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Loading from '../../components/Loading';

function Albuns() {
  const [isOpen, setIsOpen] = useState(false);
  const [albums, setAlbums] = useState([]);
  const [selectedAlbum, setSelectedAlbum] = useState(null);
  const [albumPhotos, setAlbumPhotos] = useState([]);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [albumLoading, setAlbumLoading] = useState(false);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    async function fetchAlbumsAndCovers() {
      const storage = getStorage();
      const albumsRef = ref(storage, 'photos');
      const albumsList = await listAll(albumsRef);

      const albumData = [];

      for (const album of albumsList.prefixes) {
        const albumName = album.name;

        // Listar as fotos do álbum e obter a primeira foto como capa
        const photosRef = ref(storage, `photos/${albumName}`);
        const photosList = await listAll(photosRef);

        if (photosList.items.length > 0) {
          const photo = photosList.items[0];
          const photoURL = await getDownloadURL(photo);

          albumData.push({
            albumName,
            coverURL: photoURL,
          });
        }
      }

      setAlbums(albumData);
      setLoading(false);
    }

    fetchAlbumsAndCovers();
  }, []);

  const handleAlbumClick = async (album) => {
    setSelectedAlbum(album);
    setAlbumLoading(true);

    // Buscar fotos do álbum selecionado
    const storage = getStorage();
    const photosRef = ref(storage, `photos/${album.albumName}`);
    const photosList = await listAll(photosRef);

    const photoData = [];

    for (const photo of photosList.items) {
      const photoURL = await getDownloadURL(photo);
      photoData.push({
        url: photoURL,
      });
    }

    setAlbumPhotos(photoData);
    setAlbumLoading(false);
  };

  const closeModal = () => {
    setSelectedAlbum(null);
  };

  const openLightbox = (photo) => {
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  // Função para avançar para a próxima foto
  const nextPhoto = () => {
    setCurrentPhotoIndex((prevIndex) => (prevIndex === albumPhotos.length - 1 ? 0 : prevIndex + 1));
  };

  // Função para voltar para a foto anterior
  const prevPhoto = () => {
    setCurrentPhotoIndex((prevIndex) => (prevIndex === 0 ? albumPhotos.length - 1 : prevIndex - 1));
  };

  const closePhoto = (event) => {
    if (event.target.classList.contains('lightbox-overlay')) {
      closeLightbox();
    }
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      <div className='container'>
        <Header toggleSidebar={toggleSidebar} />
        <div className='padding' />

        <div className='content'>
          <h2 className='albumH4'>Albuns</h2>
          {loading ? (
            <Loading />
          ) : (
            <div className='albums contentAlbums'>
              {albums.map((album) => (
                <div key={album.albumName} className='album' onClick={() => handleAlbumClick(album)}>
                  <img src={album.coverURL} alt={album.albumName} loading='lazy' />
                  <h3>{album.albumName}</h3>
                </div>
              ))}
            </div>
          )}

          {selectedAlbum && (
            <div className='modal'>
              <div className='modal-content'>
                <button onClick={closeModal}>X</button>
                {albumLoading ? (
                  <Loading />
                ) : (
                  <div className='album-photos'>
                    {albumPhotos.map((photo, index) => (
                      <img
                        key={index}
                        src={photo.url}
                        alt={`${index + 1}`}
                        onClick={() => openLightbox(photo)}
                        loading='lazy'
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}

          {lightboxOpen && albumPhotos.length > 0 && (
            <div className="lightbox-overlay" onClick={closePhoto}>
              <button className="prev-button" onClick={prevPhoto}>❮</button>
              <div className="lightbox-content">
                <img src={albumPhotos[currentPhotoIndex].url} alt="Selected" loading="lazy" />
              </div>
              <button className="next-button" onClick={nextPhoto}>❯</button>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Albuns;

import React, { useState, useEffect, useRef, useMemo } from "react";
import "./roda.css";
import { getAuth } from "firebase/auth";
import { db } from '../../auth/firebaseConnection';
import { query, addDoc, getDocs, serverTimestamp, collection, orderBy } from "firebase/firestore";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import Chart from "chart.js/auto";
import RodaData from "./rodas";


function RodaMpm({ setNotification }) {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState(Array(12).fill(0));
  const [rodasMenores, setRodasMenores] = useState([]);
  const chartRef = useRef(null);

  const pilarNames = useMemo(
    () => [
      "Ame-se",
      "Liberte-se",
      "Perdoe-se",
      "Espiritualize-se",
      "Empreenda-se",
      "Inove-se",
      "Mova-se",
      "Transforme-se",
      "Medite-se",
      "Autoresponsabilize-se",
      "Organize-se",
      "Celebre-se",
    ],
    []
  );

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.destroy();
    }

    const ctx = document.getElementById("radarChart").getContext("2d");

    const newChart = new Chart(ctx, {
      type: "radar",
      data: {
        labels: pilarNames,
        datasets: [
          {
            label: 'Notas',
            data: data,
            backgroundColor: 'transparent',
            borderColor: "#CA60D6",
          },
        ],
      },
      options: {
        scales: {
          r: {
            suggestedMin: 0,
            suggestedMax: 10,
            ticks: {
              beginAtZero: false,
              stepSize: 1,
              maxTicksLimit: 11,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });

    chartRef.current = newChart;

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };

  }, [data, pilarNames]);


  const handleResize = () => {
    if (chartRef.current) {
      chartRef.current.resize();
      chartRef.current.update();
    }
  };

  const handleChange = (index, value) => {
    const newData = [...data];
    newData[index] = value;
    setData(newData);
  };

  const handleKeyDown = (e, index) => {
    const inputValue = e.target.value;
    const isValidInput =
      /^\d{0,2}$/.test(inputValue) && inputValue <= 10;

    if (e.key !== "Backspace" && !isValidInput) {
      e.preventDefault();
    }
  };;

  useEffect(() => {
    const fetchData = async () => {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        try {
          const userCollectionRef = collection(db, "users", user.uid, "rodaData");
          const querySnapshot = await getDocs(query(userCollectionRef, orderBy("timestamp", "desc")));

          const rodasMenoresData = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data().data;
            const labels = pilarNames;
            const id = doc.id;
            const timestamp = doc.data().timestamp.toDate().toLocaleString();

            rodasMenoresData.push({ id, data, labels, timestamp });
          });

          setRodasMenores(rodasMenoresData);
        } catch (error) {
          setNotification({ message: 'Erro, Verifique seus dados e tente novamente.', type: 'error' });
        }
      }
    };

    fetchData();
  }, [pilarNames, setNotification]);


  // Criar colecao de dados da roda no DB
  const saveDataToFirestore = async () => {
    if (data.some(value => value === 0)) {
      setNotification({ message: 'Preencha todos os Pilares antes de salvar.', type: "warning" })
      return;
    }

    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      try {
        const userCollectionRef = collection(db, "users", user.uid, "rodaData");

        // salva a roda no DB
        const docRef = await addDoc(userCollectionRef, {
          data: data,
          timestamp: serverTimestamp(),
        });

        // adiciona a roda recem salvaa lista
        const newRoda = {
          id: docRef.id,
          data:data,
          labels: pilarNames,
          timestamp: new Date().toLocaleString(),
        };
        setRodasMenores([newRoda, ...rodasMenores])

        setNotification({ message: 'Roda da positividade salva com sucesso.', type: 'success' });

        // Limpe os campos após salvar
        setData(Array(12).fill(0));
      } catch (error) {
        setNotification({ message: 'Erro ao salvar, tente novamente.', type: 'error' });
      }
    } else {
      setNotification({ message: 'Erro, Nenhum usuário loado.', type: 'error' });
    }
  };


  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      <div className="container">
        <Header toggleSidebar={toggleSidebar} />
        <div className="padding" />

        <div className="content">
          <h2 className="rodaH2">Roda da Mulher Positiva</h2>
          <div className="roda">
            {data.map((value, index) => (
              <div key={index}>
                <label>{`${pilarNames[index]}: `}</label>
                <input
                  type="number"
                  min="0"
                  max="10"
                  value={value}
                  onChange={(e) => {
                    const newValue = parseInt(e.target.value, 10) || 0;
                    handleChange(index, Math.min(10, Math.max(0, newValue)));
                  }}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                />
              </div>
            ))}
          </div>
          <div className="roda-container">
            <canvas id="radarChart" />
          </div>
          <button className="saveRoda" onClick={saveDataToFirestore}>Salvar Roda</button>
        </div>

        <h2 className="rodasH2">Histórico</h2>
        <div className="rodas-container">
          {rodasMenores.map((rodaData) => (
            <RodaData key={rodaData.id} rodaData={rodaData} className='roda-div' />
          ))}
        </div>
      </div>
    </>
  );
}

export default RodaMpm;

import React from 'react';
import './loading.css';

function Loading() {
  return (
    <div className="loading-container">
            
      {/* <div className="loading-text">
        <span>P</span>
        <span>O</span>
        <span>S</span>
        <span>I</span>
        <span>T</span>
        <span>I</span>
        <span>V</span>
        <span>E</span>
        <span>-</span>
        <span>S</span>
        <span>E</span>
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </div> */}
     
      <div className="loading-container">
        <div className="loading-spinner"></div>
      </div>
     
    </div>

  );
};

export default Loading;

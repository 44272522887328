import React, { useState, useEffect } from "react";
import { auth } from "../auth/firebaseConnection";
import { onAuthStateChanged } from "firebase/auth";
import { Navigate, useNavigate } from "react-router-dom";
import checkUserAccess from "./checkUserAccess";

export default function Private({ children, allowedUserTypes, setNotification }) {
  const [loading, setLoading] = useState(true);
  const [logado, setLogado] = useState(false);
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate(); 

  useEffect(() => {
    async function checkLogin() {
      const unsub = onAuthStateChanged(auth, (user) => {
        if (user) {
          const userData = {
            uid: user.uid,
          };
  
          setUserData(userData);
          localStorage.setItem("@detalheUsuario", JSON.stringify(userData));
  
          setLoading(false);
          setLogado(true);
        } else {
          setLoading(false);
          setLogado(false);
          setUserData(null);
        }
      });
  
      return unsub;
    }
  
    checkLogin();
  }, []);

  useEffect(() => {
    // Verifique se o usuário tem acesso ao tipo de usuário permitido
    if (allowedUserTypes && userData) {
      Promise.all(allowedUserTypes.map(userType => checkUserAccess(userType)))
        .then(accessResults => {
          if (!accessResults.includes(true)) {

            navigate("/home");
          }
        })
        .catch(error => {
          setNotification({ message: 'Erro ao verificar acesso', type: 'error' });
          navigate("/");
        });
    }
  }, [allowedUserTypes, userData, navigate, setNotification]);

  if (loading) {
    return <div>Carregando...</div>;
  }

  if (!logado) {
    return <Navigate to="/" />;
  }

  return children;
}

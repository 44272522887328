import React, { useEffect } from "react";
import Chart from "chart.js/auto";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';

function RodasData({ rodaData }) {
  const chartRef = React.useRef(null);

  useEffect(() => {
    const ctx = document.getElementById(`radarChart_${rodaData.id}`).getContext("2d");

    const newChart = new Chart(ctx, {
      type: "radar",
      data: {
        labels: rodaData.labels,
        datasets: [
          {
            label: 'Notas',
            data: rodaData.data,
            backgroundColor: 'transparent',
            borderColor: "#CA60D6",
          },
        ],
      },
      options: {
        scales: {
          r: {
            suggestedMin: 0,
            suggestedMax: 10,
            ticks: {
              beginAtZero: false,
              stepSize: 1,
              maxTicksLimit: 11,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });

    chartRef.current = newChart;

    return () => {
      // Limpar o gráfico quando o componente for desmontado
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, [rodaData.labels, rodaData.data, rodaData.id]);

  return (
    <div>
      <p><FontAwesomeIcon icon={faCalendar} /> {rodaData.timestamp}</p>
      <canvas id={`radarChart_${rodaData.id}`} />
    </div>
  );
};

export default RodasData;

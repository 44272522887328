import React, { useState, useEffect } from 'react'
import './conteudos.css';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import Ebook from '../../assets/img/ebook.jpg'
import EbookPlano from '../../assets/img/ebookPlano.png'
import EbookVida from '../../assets/img/capavida.jpeg'
import EbookMovase from '../../assets/img/capamovase.jpg'
import Planner from '../../assets/img/plannerMpm.png'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';


function Conteudos() {
  const [isOpen, setIsOpen] = useState(false);
  const [cursos, setCursos] = useState([]);


  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const pdfUrl = process.env.PUBLIC_URL + '/E-book-SerEmpreendedora.pdf'
  const pdfUrl2 = process.env.PUBLIC_URL + '/plano-de-acao-como-fazer-um.pdf'
  const pdfUrl3 = process.env.PUBLIC_URL + '/Estrategias-para-Qualidade-de-Vida.pdf'
  const pdfUrl4 = process.env.PUBLIC_URL + '/ebook-movase.pdf'

  const pdfUrlPlanner = process.env.PUBLIC_URL + './planner-pm.pdf'

  useEffect(() => {
    const fetchCursos = async () => {
      try {
        const db = getFirestore();
        const cursosCollection = collection(db, 'videoAulas');
        const cursosSnapshot = await getDocs(cursosCollection);
        const cursosData = cursosSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        cursosData.sort((a, b) => b.timestamp - a.timestamp);

        setCursos(cursosData);
      } catch (error) {
        console.error('Erro ao buscar cursos:', error);
      }
    };

    fetchCursos();
  }, []);


  return (
    <>
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />

      <div className='container'>
        <Header toggleSidebar={toggleSidebar} />
        <div className='padding' />

        <div className='content'>
          <h2>Cursos</h2>
          <div className='cursos'>
            <Swiper
               spaceBetween={10} // Espaço entre os slides
               navigation // Botões de navegação
               pagination={{ clickable: true }} // Paginação clicável
               scrollbar={{ draggable: true }} // Barra de rolagem arrastável
               breakpoints={{
                 320: { slidesPerView: 1.2 },
                 480: { slidesPerView: 1.5 },
                 640: { slidesPerView: 2 },
                 768: { slidesPerView: 2.5 },
                 1024: { slidesPerView: 3.2 },
                 1280: { slidesPerView: 4.2 },
                 1440: { slidesPerView: 4.5 },
                 1600: { slidesPerView: 5.5},
               }}
            >
              {cursos.map(curso => (
                <SwiperSlide key={curso.id}>
                  <Link to={`/curso/${curso.id}`}>
                    <img src={curso.capaCursoUrl} alt={`Capa do Curso - ${curso.title}`} />
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

        </div>

        <div className='content'>
          <h2>Ebooks</h2>
          <div className='ebooks'>
            <a href={pdfUrl} download="E-book-SerEmpreendedora.pdf" className='ebook'>
              <img src={Ebook} alt='PDF Se Empreendedora' />
              <div className='overlayEbook'>Baixar</div>
            </a>
            <a href={pdfUrl2} download="plano-de-acao-como-fazer-um.pdf" className='ebook'>
              <img src={EbookPlano} alt='PDF Ebook Plano de ação' />
              <div className='overlayEbook'>Baixar</div>
            </a>
            <a href={pdfUrl3} download="Estrategias-para-Qualidade-de-Vida.pdf" className='ebook'>
              <img src={EbookVida} alt='PDF Ebook Qualidade de vida' />
              <div className='overlayEbook'>Baixar</div>
            </a>
            <a href={pdfUrl4} download="ebook-movase.pdf" className='ebook'>
              <img src={EbookMovase} alt='PDF Ebook Mova-se de vida' />
              <div className='overlayEbook'>Baixar</div>
            </a>
          </div>
        </div>

        <div className='content'>
          <h2>Planner PM para imprimir</h2>
          <div className='ebooks'>
            <a href={pdfUrlPlanner} download="Planner-pm.pdf" className='ebook'>
              <img src={Planner} alt='PDF Planner PM' />
              <div className='overlayEbook'>Baixar</div>
            </a>
          </div>
        </div>

      </div>
    </>
  )
}

export default Conteudos;

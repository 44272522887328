  import React, { useState, useEffect } from 'react';
  import './ranking.css'
  import { getFirestore, collection, query, getDocs } from 'firebase/firestore';
  import Header from "../../components/Header"
  import Sidebar from "../../components/Sidebar"
  import Avatar from '../../assets/img/woman.png'
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
  import { faMedal } from '@fortawesome/free-solid-svg-icons';
  
  
  function Ranking() {
    const [users, setUsers] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [dataCarregada, setDataCarregada] = useState(false);
    const [processedUsers, setProcessedUsers] = useState([]);
  
    const toggleSidebar = () => {
      setIsOpen(!isOpen);
    };
  
    useEffect(() => {
      const buscarUsuarios = async () => {
        try {
          const db = getFirestore();
          const colecoesUsuarios = ['userBasic', 'userPremium', 'userMasters'];
  
          const todosUsuarios = [];
          for (const colecao of colecoesUsuarios) {
            const consulta = query(collection(db, colecao));
            const resultadosConsulta = await getDocs(consulta);
  
            resultadosConsulta.forEach(doc => {
              const dadosUsuario = doc.data();
              const nomeUsuario = dadosUsuario.nome;
              todosUsuarios.push({ idAuth: dadosUsuario.idAuth, name: nomeUsuario, score: 0 });
            });
          }
  
          setUsers(todosUsuarios);
          setDataCarregada(true);
        } catch (erro) {
          console.error('Erro ao buscar usuários:', erro);
        }
      };
  
      buscarUsuarios();
    }, []);
  
    useEffect(() => {
      const calcularPontuacoes = async () => {
        if (!dataCarregada) return;
  
        try {
          const db = getFirestore();
          const colecaoVideoAulas = collection(db, 'videoAulas');
          const documentosVideoAulas = await getDocs(colecaoVideoAulas);
  
          const updatedUsers = [...users];
  
          for (const documentoVideoAula of documentosVideoAulas.docs) {
            const idVideoAula = documentoVideoAula.id;
  
            const colecaoCompletos = collection(db, 'videoAulas', idVideoAula, 'userCompletions');
            const documentosCompletos = await getDocs(colecaoCompletos);
  
            for (const documentoCompleto of documentosCompletos.docs) {
              const idUsuario = documentoCompleto.id;
  
              if (processedUsers.includes(idUsuario)) continue;
  
              const videosCompletos = Object.values(documentoCompleto.data().completedVideos).reduce(
                (contagem, valor) => (valor === true ? contagem + 1 : contagem),
                0
              );
  
              const indiceUsuario = updatedUsers.findIndex(usuario => usuario.idAuth === idUsuario);
  
              if (indiceUsuario !== -1) {
                updatedUsers[indiceUsuario].score += videosCompletos * 100;
                setProcessedUsers(prevProcessedUsers => [...prevProcessedUsers, idUsuario]);
              }
            }
          }
  
          setUsers(updatedUsers);
        } catch (erro) {
          console.error('Erro ao calcular pontuações dos usuários:', erro);
        }
      };
  
        calcularPontuacoes();
    }, [dataCarregada, processedUsers]);
    
    return (
      <>
        <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
        <div className='container'>
          <Header toggleSidebar={toggleSidebar} />
          <div className="padding" />
          <div className="ranking-container content">
            <h2>Ranking de Pontuações</h2>
            <center>Confira quem está no top 10 das Positivas!</center>
            <div className='usuarios-table'>
              <table>
                <thead>
                  <tr>
                    <th>Colocação</th>
                    <th></th>
                    <th><FontAwesomeIcon icon={faMedal} style={{ fontSize: '25px' }} /></th>
                  </tr>
                </thead>
                <tbody>
                  {users.sort((a, b) => b.score - a.score).map((user, index) => ( // Sort users by score (descending)
                    <tr key={index} className="user-row">
                      <td>{index + 1 + 'º'}</td>
                      <td><img src={user.avatar || Avatar} alt="Avatar" />{user.name}</td>
                      <td>{user.score}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    );
  }
  
  export default Ranking
import { getAuth } from "firebase/auth";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../auth/firebaseConnection";

async function checkUserAccess(userType) {
  const auth = getAuth();
  const currentUserEmail = auth.currentUser ? auth.currentUser.email : null;

  try {
    const userCollection = collection(db, userType);
    const userSnapshot = await getDocs(userCollection);
    const userEmails = userSnapshot.docs.map((doc) => doc.data().email);

    return userEmails.includes(currentUserEmail);
  } catch (error) {
    console.error(`Erro ao verificar acesso para ${userType}:`, error);
    return false;
  }
}

export default checkUserAccess;

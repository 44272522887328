import React, { useState, useEffect } from 'react';
import './header.css';
import { getAuth } from "firebase/auth";
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarsStaggered, } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';



function Header({ toggleSidebar }) {
  const auth = getAuth();
  const user = auth.currentUser;
  const userEmail = user ? user.email : null;
  const [userName, setUserName] = useState('')

  useEffect(() => {
    const fetchUserName = async () => {
      if (userEmail) {
        try {
          const db = getFirestore();
          const userBasicColection = collection(db, 'userBasic');
          const userPremiumCollection = collection(db, 'userPremium');
          const userMastersCollection = collection(db, 'userMasters');

          // Procurar nome nas colecoes
          const basicQuery = query(userBasicColection, where('email', '==', userEmail));
          const basicSnapshot = await getDocs(basicQuery);
          if (!basicSnapshot.empty) {
            setUserName(getFirstName(basicSnapshot.docs[0].data().nome));
            return;
          }

          const mastersQuery = query(userMastersCollection, where('email', '==', userEmail));
          const mastersSnapshot = await getDocs(mastersQuery);
          if (!mastersSnapshot.empty) {
            setUserName(getFirstName(mastersSnapshot.docs[0].data().nome));
            return;
          }

          const premiumQuery = query(userPremiumCollection, where('email', '==', userEmail));
          const premiumSnapshot = await getDocs(premiumQuery);
          if (!premiumSnapshot.empty) {
            setUserName(getFirstName(premiumSnapshot.docs[0].data().nome));
            return;
          }
        } catch (error) {
          console.error('Erro ao buscar usuario')
        }
      }
    };

    const getFirstName = (fullName) => {
      const  firstName = fullName.split(' ')[0];
      return firstName
    }

    fetchUserName();
  },[userEmail])

  return (
    <>
      <div className='header'>
        <div className="logo-details">
          <FontAwesomeIcon
            icon={faBarsStaggered}
            onClick={toggleSidebar}
            id="btn"
          />
        </div>
      </div>
      <div className='usuario'>
        <Link className='link1' to='/dados-usuario'>Bem-vinda, Positiva! <span>{userName} </span>㊂</Link>
        <Link className='link2' to='/dados-usuario'>Olá, <span>{userName} </span>㊂</Link>
      </div>
    </>
  );
}

export default Header;
